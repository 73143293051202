import { url } from './settings'
import { useOutletContext, useParams } from "react-router-dom"
import { useEffect, useRef, useState } from 'react'
import ava from '../logo.svg'

export default function Chat() {
  const [boxRef] = useOutletContext()
  let { user } = useParams()
  let mbRef = useRef();
  let messageRef = useRef();
  const [data,setData] = useState([])
  const [offset,setOffset] = useState(0)
  useEffect(()=>{
    let formData = new FormData()
    formData.append('token',window.localStorage['token'])
    formData.append('username',user)
    formData.append('offset',0)
    fetch(url + '?action=getMessages', {method: 'POST', body: formData})
    .then(response => response.json())
    .then(dt => {
      dt['result'].messages.reverse()
      setData(dt['result'])
      setOffset(15)
      setTimeout(()=>{mbRef.current.scrollTop = 9999},0)
    })
    boxRef.current.classList.add('opened')
  },[user])

  function sendMessage() {
    let msg = messageRef.current.value;
    messageRef.current.value = '';
    if (msg != '') {
      let formData = new FormData()
      formData.append('token',window.localStorage['token'])
      formData.append('user',user)
      formData.append('text',msg)
      fetch(url + '?action=sendMessage', {method: 'POST', body: formData})
      .then(() => {
        let formData2 = new FormData()
        formData2.append('token',window.localStorage['token'])
        formData2.append('username',user)
        formData2.append('offset',0)
        fetch(url + '?action=getMessages', {method: 'POST', body: formData2})
        .then(response => response.json())
        .then(dt => {
          dt['result'].messages.reverse()
          setData(dt['result'])
          setOffset(15)
          setTimeout(()=>{mbRef.current.scrollTop = 9999},0)
        })
      })
    }
  }
  function sendFile(type,file) {
    let formData = new FormData()
    formData.append('token',window.localStorage['token'])
    formData.append('user',user)
    formData.append('type',type)
    formData.append('file',file)
    fetch(url + '?action=sendFile', {method: 'POST', body: formData})
    .then(response => response.json())
    .then(() => {
      let formData2 = new FormData()
      formData2.append('token',window.localStorage['token'])
      formData2.append('username',user)
      formData2.append('offset',0)
      fetch(url + '?action=getMessages', {method: 'POST', body: formData2})
      .then(response => response.json())
      .then(dt => {
        dt['result'].messages.reverse()
        setData(dt['result'])
        setOffset(15)
        setTimeout(()=>{mbRef.current.scrollTop = 9999},0)
      })
    })
  }
  function scrollHundler(scroll) {
    if (scroll == 0) {
      setOffset(prev => prev + 15)
      let formData2 = new FormData()
      formData2.append('token',window.localStorage['token'])
      formData2.append('username',user)
      formData2.append('offset',offset)
      fetch(url + '?action=getMessages', {method: 'POST', body: formData2})
      .then(response => response.json())
      .then(dt => {
        dt['result'].messages.reverse()
        let newData = dt['result']
        newData.messages.push(...data.messages)
        let sH = mbRef.current.scrollHeight
        setData(newData)
        setTimeout(()=>{mbRef.current.scrollTop = mbRef.current.scrollHeight - sH},0)
      })
    }
  }
  return(
<>
<div className="messages">
  <div className="messages-box" ref={mbRef} onScroll={(event) => {scrollHundler(event.currentTarget.scrollTop)}}>
    {data.messages && data.messages.map((mes) => {
      let a = new Date(mes.date * 1000)
      let dt = `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()} ${a.getHours()}:${a.getMinutes()}:${a.getSeconds()}`;
      let photo = data.photo[0] == '@' ? {backgroundColor: `hsl(${data.photo.slice(1)}deg 100% 60%)`} : { backgroundImage: `url(https://twerp.store/bot/${data.photo})` }
      let photo_id = '';
      if (mes.photo_id != '') {
        photo_id = <img src={`https://twerp.store/bot/index.php?getfile=${mes.photo_id}`} />
      }
      let video_id = '';
      if (mes.video_id != '') {
        video_id = <video controls="controls" src={`https://twerp.store/bot/index.php?getfile=${mes.video_id}`} />
      }
    return(
      <div className="mes" key={mes.id}>
        <div
          className={`dialog__photo ${mes.from_bot === '0' && data.photo[0] == '@' ? 'empty' : ''}`}
          data-name={data.name.split(' ').reduce((acc, prev) => {return acc + prev[0]},'')}
          style={mes.from_bot === '1' ? { backgroundImage: `url('${ava}')` } : photo}
        />
        <div className="dialog__body">
          <div className="dialog__body_top body_xs">
            <div className="dialog__body_title">{mes.from_bot === '1' ? 'CarFax' : data.login ? <a href={`tg://resolve?domain=${data.login}`}>{data.name} @{data.login}</a> : data.name}</div>
            <div className="dialog__body_time text300">{dt}</div>
          </div>
          <div className="dialog__body_mes body_s text400">{photo_id}{video_id}{mes.text}</div>
        </div>
      </div>
    )})}
  </div>
  <div className="input-wrapper" style={{ display: "flex" }}>
    <div className='input-attach'>
      <svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 0 24 24" fill="#9E9E9E"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M16.5 6.75v10.58c0 2.09-1.53 3.95-3.61 4.15-2.39.23-4.39-1.64-4.39-3.98V5.14c0-1.31.94-2.5 2.24-2.63 1.5-.15 2.76 1.02 2.76 2.49v10.5c0 .55-.45 1-1 1s-1-.45-1-1V6.75c0-.41-.34-.75-.75-.75s-.75.34-.75.75v8.61c0 1.31.94 2.5 2.24 2.63 1.5.15 2.76-1.02 2.76-2.49V5.17c0-2.09-1.53-3.95-3.61-4.15C9.01.79 7 2.66 7 5v12.27c0 2.87 2.1 5.44 4.96 5.71 3.29.3 6.04-2.26 6.04-5.48V6.75c0-.41-.34-.75-.75-.75s-.75.34-.75.75z"></path></svg>
      <div className="attach-wrapper body_s text400">
        <label htmlFor="photo" className="attach">Send photo</label>
        <label htmlFor="video" className="attach">Video</label>
        <label htmlFor="doc" className="attach">Document</label>
      </div>
    </div>
    <input type="file" onChange={(e)=>{sendFile('photo',e.target.files[0])}} id='photo' accept='image/jpeg,image/jpeg,image/png,image/gif' style={{display:'none'}} />
    <input type="file" onChange={(e)=>{sendFile('video',e.target.files[0])}} id='video' accept='video/mp4,video/mpeg,video/ogg,video/quicktime,video/3gpp,video/webm' style={{display:'none'}} />
    <input type="file" onChange={(e)=>{sendFile('doc',e.target.files[0])}} id='doc' style={{display:'none'}} />
    <textarea onKeyDown={(e) => {if (e.keyCode == 13) {e.preventDefault(); e.stopPropagation(); sendMessage()}}} ref={messageRef} className="body_s" name="msg" onInput={(e)=>{e.currentTarget.style.height = e.currentTarget.value.split('\n').length * 18 + 28 + 'px'}} style={{height: '46px'}} placeholder="Write message"></textarea>
    <svg
      onClick={sendMessage}
      width={20}
      height={20}
      viewBox="0 0 20 20"
      stroke="#9092A2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <use xlinkHref="#mes_icon" />
    </svg>
  </div>
  <div className="messages-back body_s" onClick={()=>{boxRef.current.classList.remove('opened')}}>
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.25 9H3.75"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 14.25L3.75 9L9 3.75"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
    Back to chats
  </div>
</div>
<div className="command-block">
  <h2>{data.name}</h2>
  <div className='body_s'>
    <div className='text200'>ID</div>
    <span className='text600'>{user}</span>
    {data.login && <>
      <div className='text200'>Login</div>
      <span className='text600'><a href={`tg://resolve?domain=${data.login}`}>@{data.login}</a></span>
    </>}
    <div className='text200'>First activity:</div>
    <span className='text600'>{data.min}</span>
    <div className='text200'>Last activity:</div>
    <span className='text600'>{data.max}</span>
  </div>
</div>
</>
    )
}