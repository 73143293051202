import { useRef, useState } from "react"
import reload from "../update.png"
import { url } from '../components/settings'
import { useEffect } from "react"


export default function Order({context, user, langs, setUser}) {
    const [submit,setSubmit] = useState(null)
    const [reports,setReports] = useState([])
    const [error,setError] = useState(false)
    const [open,setOpen] = useState(false)
    const [lang,setLang] = useState(['en','English'])
    const ref = useRef()
    const languages = {'ru': 'Русский', 'ua': 'Українська', 'en': 'English', 'es': 'Español', 'fr': 'Français', 'de': 'Deutsch', 'ja': '日本語', 'hi': 'हिन्दी', 'ar': 'العربية', 'pt': 'Português', 'bn': 'বাংলা', 'ru': 'Русский', 'en': 'English', 'pa': 'ਪੰਜਾਬੀ', 'jv': 'Basa Jawa', 'vi': 'Tiếng Việt', 'ko': '한국어', 'it': 'Italiano', 'te': 'తెలుగు', 'tr': 'Türkçe', 'mr': 'मराठी', 'pl': 'Polski'}
    const [settings,setSettings] = useState({status: 1, banner_link: '', banner_title: '', banner_text: '', banner_status: 0})
    
    const handleSubmit = (e) => {
        e.preventDefault()
        if (ref.current.reportValidity()) {
          let formData = new FormData(ref.current)
          formData.append('token',window.localStorage['token'])
          if (error && submit === context.orderErrorVinCoincidence) {
            formData.append('ignoreVinCoincidence',1)
          }
          fetch(url + '?action=getReport', {method: 'POST', body: formData})
          .then(response => response.json())
          .then(r => {
            if (r.result) {
                setError(false)
                setSubmit(context.orderPending)
                ref.current.querySelectorAll('input[type="text"]').forEach(inp => {inp.value = ''})
                const newUser = {...user}
                newUser.reports = +newUser.reports + 1
                setUser(newUser)
                getReports()
            } else {
                setError(true)
                setSubmit(context[r.error])
            }
          })
        }
    }

    const getReports = () => {
        let formData = new FormData()
        formData.append('token',window.localStorage['token'])
        fetch(url + '?action=getReports', {method: 'POST', body: formData})
        .then(response => response.json())
        .then(r => {
            if (r.result) {
                setReports(r.data)
            }
        })
    }
    const getSettings = () => {
        let formData = new FormData()
        formData.append('token',window.localStorage['token'])
        fetch(url + '?action=getSettings', {method: 'POST', body: formData})
        .then(response => response.json())
        .then(r => {
            if (r.result) {
                setSettings(r.data)
            }
        })
    }

    const linkClickHandler = () => {
        alert(context.orderDownloadAlert);
    }

    useEffect(()=>{
        getReports()
        getSettings()
    },[])

    return(<div className="align-center">
        <h2>{context.orderTitle}</h2>  
        <form ref={ref} onSubmit={handleSubmit}>
        <div className="input-wrapper">
            <input type="text" className="form-control" placeholder="vin" name="vin" required />
            <span>vin</span>
        </div>
        <div className="input-wrapper">
            <input type="text" className="form-control" placeholder={context.orderComment} name="comment" />
            <span>{context.orderComment}</span>
        </div>
        <div className="row">
            <div className="col">
                <div className="input-wrapper" id="lang" onClick={()=>{setOpen((prev) => !prev)}}>
                    <div className={`input js-select ${open ? 'opened' : ''}`}>
                        <input type="hidden" id="translate" name="translate" value={lang[0]} />
                        <span>{context.orderTranslate}</span>
                        <div className="var">{lang[1]}</div>
                        <svg width="20" height="20" viewBox="0 0 20 20" stroke="#C7C9D1" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 6L8 10L12 6" stroke="inherit" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>
                        <div className="variants">
                            {false && <div className="variant" onClick={(e)=>{setLang([e.currentTarget.getAttribute('data'),e.currentTarget.innerText])}} data="en">English</div>}
                            {false && <div className="variant" onClick={(e)=>{setLang([e.currentTarget.getAttribute('data'),e.currentTarget.innerText])}} data="ru">Русский</div>}
                            {langs.map((l,i) => <div key={i} className="variant" onClick={(e)=>{setLang([e.currentTarget.getAttribute('data'),e.currentTarget.innerText])}} data={l}>{languages[l]}</div>)}
                        </div>
                    </div>
                </div>
            </div>
            <div className="col">
                <button className="btn btn-primary px-4" type="submit">{context.orderBtn}</button>
            </div>
        </div>
        <div className={`submit-results body_s ${error ? 'error' : ''} ${submit ? '' : 'hidden'}`}>{submit}</div>
        </form>	
        <div className="history-info">
            <p>{context.orderReports}: <b>{user.reports}</b>. {context.orderDeposit}: <b>{user.deposit}</b>. {context.orderAvailable}: <b>{+user.deposit - +user.reports}</b>.</p>
            <p>{context.orderOverdraft}: <b>{user.overdraft}</b>.</p>
        </div>
        <div className="table-wrapper">
        <table className="body_s" id="tableOrders">
            <thead>
                <tr>
                    <th>VIN</th>
                    <th>{context.orderCommentShort}</th>
                    <th>{context.orderData}</th>
                    <th>{context.orderLang}</th>
                    <th>{context.orderStatus}</th>
                    <th>{context.orderLink}
                        <a
                          className="update-reports-table"
                          onClick={(e)=>{e.preventDefault(); getReports()}} 
                          title={context.orderReload}
                          href="#"
                        ><img src={reload} /></a>		
                    </th>
                </tr>
            </thead>
            <tbody>
                {reports.map(r => {
                    return(
                    <tr key={r.id}>
                        <td>{r.vin}</td>
                        <td>{r.comment}</td>
                        <td>{r.time}</td>
                        <td>{r.translate}</td>
                        <td>
                            {r.status == 2 && <span className="badge body_xs badge-error">{context.orderStatusError}</span>}
                            {r.status == 1 && <span className="badge body_xs badge-success">{context.orderStatusComplete}</span>}
                            {r.status == 0 && <span className="badge body_xs badge-warning">{context.orderStatusPending}</span>}
                        </td>
                        <td>
                            {r.link && <a href={r.link} onClick={linkClickHandler}>{context.orderDownload}</a>}
                        </td>
                    </tr>
                )})}
            </tbody>
        </table>
        </div>
        {settings.banner_status == 1 && <div className="banner">
            <div className="banner__body">
                <h3 className="banner__title">{settings.banner_title}</h3>
                <div className="banner__Text">{settings.banner_text}</div>
            </div>
            <img src={settings.banner_link} alt="" />
        </div>}
    </div>)
}