import { useEffect, useState } from "react"
import paypal from '../paypal.png'
import coinbase from '../crypto.png'
import { url } from "../components/settings"

export default function Payments({context,geoPrices,login,l}) {
    const [state,setState] = useState(0)
    const [liqpay,setLiqpay] = useState(['',''])
    const prices = geoPrices.prices
    const [toPay,setToPay] = useState([1,prices[0][1]])
    const [paypalId,setPaypalId] = useState(null)
    const [crypto,setCrypto] = useState(null)
    const [course, setCourse] = useState(41.32)

    useEffect(()=>{
        fetch(url + '?action=getExchangeRate', {method: 'GET'})
        .then(response => response.json())
        .then(r => {
            if (r.result) {
                setCourse(r.data)
            }
        })
    },[])
    useEffect(()=>{
        if (state > 0) {
            let amount = toPay
            let json = JSON.stringify({
                "public_key":"i18408184846","version":"3","action":"pay",
                "amount":amount[1],
                "currency":"USD",
                "description":`carfaxshara.com`,
                "order_id":`${login}||${amount[0]}||${Date.now()}`
            })
            let formData = new FormData()
            formData.append('json',json)
            fetch(url + '?action=liqpay', {method: 'POST', body: formData})
            .then(response => response.json())
            .then(r => {
                if (r.result) {
                    setLiqpay([r.data,r.sign])
                }
            })

            let formData2 = new FormData()
            formData2.append('email',login)
            formData2.append('count',amount[0])
            formData2.append('value',amount[1])
            fetch(url + '?action=paypal', {method: 'POST', body: formData2})
            .then(response => response.json())
            .then(r => {
                if (r.result) {
                    setPaypalId(r.id)
                }
            })
            fetch(url + '?action=coinbase', {method: 'POST', body: formData2})
            .then(response => response.json())
            .then(r => {
                if (r.result) {
                    setCrypto(r.url)
                }
            })
        } else {
            setPaypalId(null)
            setLiqpay(['',''])
            setCrypto(null)
        }
    },[state])

    const changeInput = (value) => {
        if (value < 1) {value = 1;}
        let pr = prices[0];
        prices.map(p => {
            if (value >= p[0]) {
                pr = p;
            }
        })
        let newPrice = Math.round(+pr[1] * +value / +pr[0] * 100) / 100
        setToPay([value,newPrice])
    }

    return(<>
        <h2>{context.menu_payments}</h2>
        {state === 0 && <>
        <div className="card_buy__right">
            <h2>{context.paymentsCount} - {toPay[0]}</h2>
            <h2 className="price">
                {(toPay[1] * course).toFixed(2)} UAH ({toPay[0] >= prices[1][0] && <span className="price-old">{toPay[0] * prices[0][1]}$</span>}
                <span className="price-new">{toPay[1]}$</span>)
            </h2>
            <p>{context.paymentsInfo} - {(Math.round(+toPay[1] / +toPay[0] * 100) / 100 * course).toFixed(2)} UAH ({Math.round(+toPay[1] / +toPay[0] * 100) / 100}$)</p>
            <div className="input-wrapper" style={{marginTop: 20, marginBottom: 20}}>
				<input
                    type="range"
                    name="range"
                    id="range"
                    value={toPay[0]}
                    onChange={(e) => {changeInput(e.currentTarget.value)}}
                    min={1} max={2500}
                />
			</div>
            <div className="input-wrapper" style={{marginTop: 20, marginBottom: 20}}>
				<input
                    type="number"
                    name="reports"
                    id="reports"
                    value={toPay[0]}
                    onInput={(e) => {changeInput(e.currentTarget.value)}}
                    min={1} max={2500}
                    placeholder={context.paymentsInput}
                />
				<span>{context.paymentsInput}</span>
			</div>
            <button type="button" className="btn" style={{width: '100%'}} onClick={()=>{setState(1)}}>{context.paymentsBtn}</button>
        </div>
        {l == 'ua' && <>
            <p style={{marginTop: 20}}>Пополнение на монобанк</p>
            <p>Моно: 5375414118977395</p>
        </>}
        </>}
        {state > 0 && <>
            <a href="#" onClick={(e)=>{e.preventDefault(); setState(0)}} className="club_card_info__back" style={{marginBottom: 25}}>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.25 9H3.75" stroke="#ACADB9" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round"></path>
                    <path d="M9 14.25L3.75 9L9 3.75" stroke="#ACADB9" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round"></path>
                </svg>
                <span className="body_s text400">{context.paymentsBackBtn}</span>
            </a>
            <h2 className="stats__title">{context.paymentsCount} - {toPay[0]}</h2>
            <p>{context.paymentsPaypal}:</p>
            {paypalId && <form id="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                <input type="hidden" name="cmd" value="_xclick" />
                <input type="hidden" name="business" value="Mszaiets@gmail.com" />
                <input type="hidden" name="item_name" value="Пополнение баланса carfaxshara.com" />
                <input type="hidden" name="item_number" value={paypalId} />
                <input type="hidden" name="currency_code" value="USD" />
                <input type="hidden" name="amount" value={`${toPay[1].toFixed(2)}`} />
                <input type="hidden" name="no_shipping" value="1" />
                <input type="hidden" name="cancel_return" value="https://carfaxshara.com/payments" />
                <input type="hidden" name="return" value="https://carfaxshara.com/" />
                <input type="hidden" name="notify_url" value="https://carfaxshara.com/bot/paypal2.php" />
                <input type="image" className="paypal-btn" src={paypal} name="submit" id="submit" alt="Совершите платеж с помощью Paypal" />
            </form>}
            <p style={{marginTop: 20}}>{context.paymentsLiqpay}:</p>
            {liqpay[0] != '' && <form method="POST" action="https://www.liqpay.ua/api/3/checkout" acceptCharset="utf-8">
                <input type="hidden" name="data" value={liqpay[0]} />
                <input type="hidden" name="signature" value={liqpay[1]} />
                <input type="image" className="liqpay-btn" src="//static.liqpay.ua/buttons/p1ru.radius.png"/>
            </form>}
            <p style={{marginTop: 20}}>{context.paymentsCrypto}:</p>
            {crypto && <a className="coinbase" href={crypto}><img src={coinbase} alt="coinbase" /></a>}
        </>}
    </>)
}