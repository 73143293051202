import { useEffect } from "react"
import { useState, useRef } from "react"
import { url } from '../components/settings'


export default function Stats() {
    const [stats,setStats] = useState({orders: null, activity: null, geo: []})
    const ref = useRef()

    const getStats = () => {
        let formData = new FormData()
        formData.append('token',window.localStorage['token'])
        let date1 = ref.current.querySelector('#date1').value
        let date2 = ref.current.querySelector('#date2').value
        formData.append('date1',date1)
        formData.append('date2',date2)
        fetch(url + '?action=getStats', {method: 'POST', body: formData})
        .then(response => response.json())
        .then(r => {
            if (r.result) {
                setStats(r.data)
            }
        })
    }

    useEffect(()=>{
        getStats()
    },[])

    const handleSubmit = (e) => {
        e.preventDefault()
        getStats()
    }
    const formReset = (e) => {
        e.preventDefault()
        ref.current.reset()
        getStats()
    }

    return(<>
        <h1>Статистика</h1>
        <h2 style={{marginTop: 40}}>Выбор промежутка</h2>
        <form ref={ref} style={{maxWidth: 600}} onSubmit={handleSubmit}>
            <div className="profile__row">
                <div className="input-wrapper">
                    <input type="date" name="date1" id="date1" />
                    <span>от</span>
                </div>
                <div className="input-wrapper">
                    <input type="date" name="date2" id="date2" />
                    <span>до</span>
                </div>
            </div>
            <button className="btn" type="button" onClick={formReset} style={{marginRight: 4}}>Сбросить</button>
            <button className="btn" type="submit">Применить</button>
        </form>
        <h2 style={{marginTop: 40}}>Отчеты</h2>
        {stats.orders && <>
            <p>В боте: {stats.orders.bot}</p>
            <p>На сайте: {stats.orders.site}</p>
            <p>VinCheck: {stats.orders.vincheck}</p>
            <p>Всего: {+stats.orders.site + +stats.orders.vincheck + +stats.orders.bot}</p>
        </>}
        <h2 style={{marginTop: 40}}>Активность</h2>
        {stats.activity && <>
            <p>В боте: {stats.activity.bot}</p>
            <p>На сайте: {stats.activity.site} (Авторизованных - {stats.activity.users})</p>
            <p>Новых IP адресов: {stats.activity.new}</p>
            <p>Создано кабинетов: {stats.activity.acc}</p>
            <p>Всего кабинетов: {stats.activity.acc_total}</p>
        </>}
        <h2 style={{marginTop: 40}}>ГЕО</h2>
        <div style={{maxWidth: 100}}>
            {stats.geo.map((g,i) => {return(
                <div key={i} className="profile__row">
                    <p>{g.geo}</p>
                    <p>{g.count}</p>
                </div>
            )})}
        </div>
    </>)
}