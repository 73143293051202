import { useEffect, useState } from "react"
import paypal from '../paypal.png'
import coinbase from '../crypto.png'
import { url } from "../components/settings"

export default function Pay({context,geoPrices,email,vin,toMain,l}) {
    const [liqpay,setLiqpay] = useState(['',''])
    const prices = geoPrices.prices
    const toPay = [1,prices[0][1]]
    const [paypalId,setPaypalId] = useState(null)
    const [crypto,setCrypto] = useState(null)

    useEffect(()=>{
        let amount = toPay
        let json = JSON.stringify({
            "public_key":"i18408184846","version":"3","action":"pay",
            "amount":amount[1],
            "currency":"USD",
            "description":`instant`,
            "order_id":`${email}||${vin}||${l}`
        })
        let formData = new FormData()
        formData.append('json',json)
        fetch(url + '?action=liqpay', {method: 'POST', body: formData})
        .then(response => response.json())
        .then(r => {
            if (r.result) {
                setLiqpay([r.data,r.sign])
            }
        })

        let formData2 = new FormData()
        formData2.append('email',email)
        formData2.append('vin',vin)
        formData2.append('lang',l)
        formData2.append('value',amount[1])
        fetch(url + '?action=paypal&instant=1', {method: 'POST', body: formData2})
        .then(response => response.json())
        .then(r => {
            if (r.result) {
                setPaypalId(r.id)
            }
        })
        fetch(url + '?action=coinbase&instant=1', {method: 'POST', body: formData2})
        .then(response => response.json())
        .then(r => {
            if (r.result) {
                setCrypto(r.url)
            }
        })
    },[])

    return(<section id="pay">
    <div className="container">
        <button className='club_card_info__back' onClick={toMain} style={{marginBottom: 25}}>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.25 9H3.75" stroke="#ACADB9" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round"></path>
                <path d="M9 14.25L3.75 9L9 3.75" stroke="#ACADB9" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round"></path>
            </svg>
            <span className="body_s text400">{context.paymentsBackBtn}</span>
        </button>
      
        <p>{context.paymentsPaypal}:</p>
        {paypalId && <form id="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
            <input type="hidden" name="cmd" value="_xclick" />
            <input type="hidden" name="business" value="Mszaiets@gmail.com" />
            <input type="hidden" name="item_name" value="Заказ Carfax на carfaxshara.com" />
            <input type="hidden" name="item_number" value={paypalId} />
            <input type="hidden" name="currency_code" value="USD" />
            <input type="hidden" name="amount" value={`${toPay[1].toFixed(2)}`} />
            <input type="hidden" name="no_shipping" value="1" />
            <input type="hidden" name="cancel_return" value="https://carfaxshara.com/" />
            <input type="hidden" name="return" value="https://carfaxshara.com/" />
            <input type="hidden" name="notify_url" value="https://carfaxshara.com/bot/paypal3.php" />
            <input type="image" className="paypal-btn" src={paypal} name="submit" id="submit" alt="Совершите платеж с помощью Paypal" />
        </form>}
        <p style={{marginTop: 20}}>{context.paymentsLiqpay}:</p>
        {liqpay[0] != '' && <form method="POST" action="https://www.liqpay.ua/api/3/checkout" acceptCharset="utf-8">
            <input type="hidden" name="data" value={liqpay[0]} />
            <input type="hidden" name="signature" value={liqpay[1]} />
            <input type="image" className="liqpay-btn" src="//static.liqpay.ua/buttons/p1ru.radius.png"/>
        </form>}
        <p style={{marginTop: 20}}>{context.paymentsCrypto}:</p>
        {crypto && <a className="coinbase" href={crypto}><img src={coinbase} alt="coinbase" /></a>}
    </div>
    </section>)
}