import { useEffect, useRef, useState } from 'react'
import { url } from '../components/settings'
import { NavLink, Outlet } from "react-router-dom";

export default function Messages() {
  const boxRef = useRef()
  const [dialogs,setDialogs] = useState([])
  const [filteredDialogs,setFilteredDialogs] = useState([])
  const [offset,setOffset] = useState(0)
  useEffect(() => {
    let formData = new FormData()
    formData.append('token',window.localStorage['token'])
    fetch(url + '?action=getChats', {method: 'POST', body: formData})
    .then(response => response.json())
    .then(data => {
      if (data['is_ok']) {
        setDialogs(data['result'])
        setFilteredDialogs(data['result'])
      }
    })
  },[])
  useEffect(() => {
    let x = setInterval(()=>{
      let formData = new FormData()
      formData.append('token',window.localStorage['token'])
      formData.append('limit',offset + 50)
      fetch(url + '?action=getChats', {method: 'POST', body: formData})
      .then(response => response.json())
      .then(data => {
        if (data['is_ok']) {
            setDialogs(data['result'])
            setFilteredDialogs(data['result'])
        }
      })
    },10000)
    return(()=>{clearInterval(x)})
  },[offset])
  const scrollHundler = (elem) => {
    if (Math.ceil(elem.scrollTop) >= elem.scrollHeight - elem.clientHeight) {
      let newOffset = offset + 50
      setOffset(newOffset)
      let formData = new FormData()
      formData.append('token',window.localStorage['token'])
      formData.append('offset',newOffset)
      fetch(url + '?action=getChats', {method: 'POST', body: formData})
      .then(response => response.json())
      .then(data => {
        if (data['is_ok']) {
          let newDialogs = [...dialogs]
          newDialogs.push(...data['result'])
          setDialogs(newDialogs)
          setFilteredDialogs(newDialogs)
        }
      })
    }
  }
  const applyFilter = (value) => {
    let arr = dialogs.filter(dialog => dialog.name.toLowerCase().indexOf(value.toLowerCase()) >= 0)
    setFilteredDialogs(arr)
  }
  return(
<>
    <h1>Messages</h1>
    <div ref={boxRef} className="dialog-box">
    <div className="dialogs">
  <div className="input-wrapper">
    <input type="text" className="body_s" onChange={(e)=>applyFilter(e.target.value)} placeholder="Поиск" />
  </div>
  <div className="dialog-wrapper" onScroll={(event) => {scrollHundler(event.currentTarget)}}>
    {filteredDialogs.map(dialog => {
      if (!dialog.photo) {return}
      let photo = dialog.photo[0] == '@' ? {backgroundColor: `hsl(${dialog.photo.slice(1)}deg 100% 60%)`} : { backgroundImage: `url(https://twerp.store/bot/${dialog.photo})` }
      return (
        <NavLink to={dialog.id} className="dialog"
          onClick={(e) => {
            e.currentTarget.setAttribute('data-count', 0)
            boxRef.current.classList.add('opened')
          }}
          key={dialog.id} 
          data-user={dialog.id} 
          data-count={dialog.unread}
        >
          <div
            className={`dialog__photo ${dialog.photo[0] == '@' ? 'empty' : ''}`}
            style={photo}
            data-name={dialog.name.split(' ').reduce((acc, prev) => {return acc + prev[0]},'')}
          />
          <div className="dialog__body">
            <div className="dialog__body_top">
              <div className="dialog__body_title body_s">{dialog.name}</div>
              <div className="dialog__body_time body_xs text300">{dialog.date}</div>
            </div>
            <div className="dialog__body_mes body_s text400">{dialog.mes}</div>
          </div>
        </NavLink>
      )
    })}
  </div>
</div>
<Outlet context={[boxRef]} />
    </div>
</>
    )
}