export default function Policy() {

    return(<section className="policy">
    <div className="container">
    <h2>Політика конфіденційності</h2>
    <p style={{marginTop: 20}}>На carfaxshara, доступному за адресою https://carfaxshara.com, конфіденційність наших відвідувачів є одним із наших основних пріоритетів. Цей документ Політики конфіденційності містить типи інформації, яку carfaxshara збирає та записує, а також способи її використання.</p>
    <p>Якщо у вас є додаткові питання або вам потрібна додаткова інформація щодо нашої Політики конфіденційності, не соромтеся звертатися до нас електронною поштою.</p>
    <p>Лог-файли carfaxshara дотримується стандартної процедури використання файлів журналу. Ці файли реєструють відвідувачів під час їхнього візиту на веб-сайти. Усі хостингові компанії це роблять, і це частина аналітики хостингових послуг. Інформація, зібрана файлами журналів, включає адреси інтернет-протоколів (IP), тип браузера, постачальника інтернет-послуг (ISP), мітку дати та часу, сторінки входу/виходу та, можливо, кількість кліків. Вони не пов'язані з будь-якою інформацією, яка дозволяє встановити особистість. Метою інформації є аналіз тенденцій, адміністрування сайту, відстеження руху користувачів на сайті та збір демографічної інформації.</p>
    <p>Файли cookie та веб-маяки Як і будь-який інший веб-сайт, carfaxshara використовує файли cookie. Ці файли cookie використовуються для зберігання інформації, включаючи уподобання відвідувачів та сторінки веб-сайту, які відвідувач відвідував чи відвідав. Інформація використовується для оптимізації роботи користувачів шляхом налаштування вмісту нашої веб-сторінки на основі типу браузера відвідувачів та/або іншої інформації.</p>
    <p>Політика конфіденційності Ви можете ознайомитися з цим списком, щоб знайти Політику конфіденційності для кожного з рекламних партнерів carfaxshara.</p>
    <p>Сторонні рекламні сервери або рекламні мережі використовують такі технології, як файли cookie, JavaScript чи веб-маяки, які використовуються в їхніх відповідних рекламних оголошеннях та посиланнях, що з'являються в carfaxshara і які надсилаються безпосередньо в браузер користувача. Коли це відбувається, вони автоматично отримують ваш IP-адресу. Ці технології використовуються для вимірювання ефективності їхніх рекламних кампаній та/або персоналізації рекламного контенту, який ви бачите на відвідуваних вами веб-сайтах.</p>
    <p>Зверніть увагу, що carfaxshara не має доступу чи контролю над цими файлами cookie, які використовуються сторонніми рекламодавцями.</p>
    <p>Політика конфіденційності третіх осіб Політика конфіденційності carfaxshara не поширюється на інших рекламодавців чи веб-сайти. Таким чином, ми рекомендуємо вам ознайомитися з відповідними Політиками конфіденційності цих сторонніх рекламних серверів для отримання більш детальної інформації. Вона може включати їхні методи та інструкції щодо того, як відмовитися від певних варіантів. Ви можете знайти повний список цих політик конфіденційності та їхні посилання тут: Посилання на політику конфіденційності.</p>
    <p>Ви можете вимкнути файли cookie у налаштуваннях свого браузера. Щоб дізнатися більше деталей про керування файлами cookie в певних веб-браузерах, цю інформацію можна знайти на відповідних веб-сайтах браузерів. Що таке файли cookie?</p>
    </div>
    </section>)
}