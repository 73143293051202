import { useRef, useState } from 'react';
import { url } from './settings'

export default function FormLogin({context,setPage,setToken}) {
  const [submit,setSubmit] = useState(null)
  const ref = useRef()

  const handleSubmit = (e) => {
    e.preventDefault()
    if (ref.current.reportValidity()) {
      let formData = new FormData(ref.current)
      fetch(url + '?action=login', {method: 'POST', body: formData})
      .then(response => response.json())
      .then(r => {
        if (r.result) {
          window.localStorage['token'] = r.token
          setToken(r.token)
          setPage('start')
        } else {
          setSubmit(context[r.error])
        }
      })
    }
  }

  return (<>
    <button className='club_card_info__back' onClick={(e) => {e.preventDefault(); setPage('start')}} style={{marginBottom: 25}}>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.25 9H3.75" stroke="#ACADB9" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round"></path>
          <path d="M9 14.25L3.75 9L9 3.75" stroke="#ACADB9" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round"></path>
      </svg>
      <span className="body_s text400">{context.paymentsBackBtn}</span>
    </button>
    {submit && <div className='submit-results body_s error'>{submit}</div>}
    <form ref={ref} onSubmit={handleSubmit}>
      <h1>{context.loginTitle}</h1>
      <p className="body_s text-muted">{context.loginIntro}</p>
      <div className="input-wrapper">
        <input type="email" className="form-control" placeholder={context.login} name="email" required />
        <span>{context.login}</span>
      </div>
      <div className="input-wrapper">
        <input className="form-control" type="password" placeholder={context.pass} name="password" required />
        <span>{context.pass}</span>
      </div>
      <div className="row">
        <div className="col">
          <button className="btn btn-primary px-4" type="submit">{context.loginBtn}</button>
        </div>
        <div className="col">
          <a href='#' onClick={(e) => {e.preventDefault(); setPage('forgot')}} className="btn-link" style={{marginRight: 15}}>{context.loginForgot}</a>
          <a href='#' onClick={(e) => {e.preventDefault(); setPage('reg')}} className="btn-link">{context.loginLink}</a>
        </div>
      </div>
    </form>	
  </>)
}