import { useRef, useState } from 'react';
import { url } from '../components/settings'

export default function FormReg({context,setPage}) {
  const [submit,setSubmit] = useState(null)
  const [confirmation,setConfirmation] = useState(false)
  const [error,setError] = useState(false)
  const ref = useRef()
  const refConfirm = useRef()

  const handleSubmit = (e) => {
    e.preventDefault()
    if (ref.current.reportValidity()) {
      let pass = ref.current.querySelector('input[name="password"]')
      let pass2 = ref.current.querySelector('input[name="password-confirm"]')
      let validity = true
      if (pass.value.length < 6 || pass.value.length > 32) {
        setError(true)
        setSubmit(context.regErrorPass)
        validity = false
      }
      if (pass.value !== pass2.value) {
        setError(true)
        setSubmit(context.regErrorPassConfirm)
        validity = false
      }
      if (validity) {
        let formData = new FormData(ref.current)
        fetch(url + '?action=registration', {method: 'POST', body: formData})
        .then(response => response.json())
        .then(r => {
          if (r.result) {
            setError(false)
            if (r.data === 'login') {
              setPage('login')
            } else {
              setSubmit(context.regSuccess)
              setConfirmation(true)
            }
          } else {
            setError(true)
            setSubmit(context[r.error])
          }
        })
      }
    }
  }

  const handleConfirmSubmit = (e) => {
    e.preventDefault()
    if (refConfirm.current.reportValidity()) {
      let formData = new FormData(refConfirm.current)
      fetch(url + '?action=confirm', {method: 'POST', body: formData})
        .then(response => response.json())
        .then(r => {
          if (r.result) {
            setPage('login')
          } else {
            setError(true)
            setSubmit(context[r.error])
          }
        })
    }
  }

  return (<>
    {!confirmation && 
      <button className='club_card_info__back' onClick={(e)=>{e.preventDefault(); setPage('start')}} style={{marginBottom: 25}}>
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.25 9H3.75" stroke="#ACADB9" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M9 14.25L3.75 9L9 3.75" stroke="#ACADB9" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round"></path>
        </svg>
        <span className="body_s text400">{context.paymentsBackBtn}</span>
      </button>
    }
    <div className={`submit-results body_s ${submit ? '' : 'hidden'} ${error ? 'error' : ''}`}>{submit}</div>
    {!confirmation && <form ref={ref} onSubmit={handleSubmit}>
      <h1>{context.regTitle}</h1>
      <p className="body_s text-muted">{context.regIntro}</p>
      <div className="input-wrapper">
        <input type="text" className="form-control" placeholder={context.regName} name="name" required />
        <span>{context.regName}</span>
      </div>
      <div className="input-wrapper">
        <input type="email" className="form-control" placeholder={context.login} name="email" required />
        <span>{context.login}</span>
      </div>
      <div className="input-wrapper">
        <input className="form-control" type="password" placeholder={context.pass} name="password" required />
        <span>{context.pass}</span>
      </div>
      <div className="input-wrapper">
        <input className="form-control" type="password" placeholder={context.pass2} name="password-confirm" required />
        <span>{context.pass2}</span>
      </div>
      <div className="row">
        <div className="col">
          <button className="btn" type="submit">{context.regBtn}</button>
        </div>
        <div className="col">
          <a href='#' onClick={(e) => {e.preventDefault(); setPage('login')}} className="btn-link">{context.regLink}</a>
        </div>
      </div>
    </form>}
    {confirmation && <form ref={refConfirm} onSubmit={handleConfirmSubmit}>
      <h1>{context.confirmTitle}</h1>
      <p className="body_s text-muted">{context.confirmIntro}</p>
      <div className="input-wrapper">
        <input type="text" className="form-control" placeholder={context.confirm} name="code" required />
        <span>{context.confirm}</span>
      </div>
      <button className="btn" type="submit">{context.confirmBtn}</button>
    </form>}
  </>)
}