import { url } from './components/settings'
import { Routes, Route, NavLink } from "react-router-dom";
import Contacts from './pages/contacts';
import Payments from './pages/payments';
import Order from './pages/order';
import Autocheck from './pages/autocheck';
import Admin from './pages/admin';
import Stats from './pages/stats';
import Messages from './pages/messages';
import Chat from './components/Chat';
import { useEffect, useState } from 'react';
import Offer from './components/Offer';
import Sticker from './pages/sticker';
import Resources from './pages/resources'

export default function Lk({context,geoPrices,logOut,langs,setL,l}) {
    const [lang,setLang] = useState(window.localStorage['lang'])
    const [showLangs,setShowLangs] = useState(false)
    const [user,setUser] = useState({login: '', reports: 0, deposit: 0, overdraft: 0, status: 1})
    const [status,setStatus] = useState(true)
  
    const getStatus = () => {
      let formData = new FormData()
      formData.append('token',window.localStorage['token'])
      fetch(url + '?action=getStatus', {method: 'POST', body: formData})
      .then(response => response.json())
      .then(r => {
        if (r.result) {
          setStatus(r.data == 1)
        }
      })
    }
    useEffect(() => {
      getStatus()
    },[])

    const changeLang = (l) => {
        window.localStorage['lang'] = l
        setLang(l)
        setL(l)
        setShowLangs(false)
    }

    useEffect(()=>{
        let formData = new FormData()
        formData.append('token',window.localStorage['token'])
        fetch(url + '?action=getUser', {method: 'POST', body: formData})
        .then(response => response.json())
        .then(r => {
            if (r.result) {
                setUser(r.data)
            } else {
                if (r.error == 'token') {logOut()}
            }
        })
    },[])
    useEffect(()=>{
        document.querySelectorAll('.menu__item').forEach(btn => {
            btn.addEventListener('click',() => {document.body.classList.remove('sidebar_visible')})
        })
    },[user.status])

    return(
    <div className="main">
        <div className="sidebar">
            <div className="sidebar__top">
                <div className="logo">{context.logo}</div>
                <div className="menu body_s text700">
                    <div className="menu__block">
                        <NavLink to="/" className="menu__item">
                            <svg className="menu__icon" width="24" height="24" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path xmlns="http://www.w3.org/2000/svg" fill="inherit" d="M475.656,223.142l-90.272-13.908-55.013-89.87a32.07,32.07,0,0,0-27.548-15.291L72.265,105.91A32.018,32.018,0,0,0,42.2,127.684L16,205.375V384H71.006a68,68,0,0,0,129.988,0H303.006a68,68,0,0,0,129.988,0H496V246.862A23.873,23.873,0,0,0,475.656,223.142ZM224,136.7l79.078-.63,44.1,72.047L224,208.074ZM72.521,137.908,192,136.956v71.107l-143.119-.051ZM136,400a36,36,0,1,1,36-36A36.04,36.04,0,0,1,136,400Zm232,0a36,36,0,1,1,36-36A36.04,36.04,0,0,1,368,400Zm96-48H434.918a67.982,67.982,0,0,0-133.836,0H202.918a67.982,67.982,0,0,0-133.836,0H48V240.026l330.526.529L464,253.724Z" className="ci-primary"></path>
                            </svg>
                            <div className="menu__text">{context.menu_order}</div>
                        </NavLink>
                        <NavLink to="/sticker" className="menu__item">
                            <svg className="menu__icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20.59 13.41L13.42 20.58C13.2343 20.766 13.0137 20.9135 12.7709 21.0141C12.5281 21.1148 12.2678 21.1666 12.005 21.1666C11.7422 21.1666 11.4819 21.1148 11.2391 21.0141C10.9963 20.9135 10.7757 20.766 10.59 20.58L2 12V2H12L20.59 10.59C20.9625 10.9647 21.1716 11.4716 21.1716 12C21.1716 12.5284 20.9625 13.0353 20.59 13.41V13.41Z" stroke="inherit" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M7 7H7.01" stroke="inherit" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            <div className="menu__text">{context.menu_sticker}</div>
                        </NavLink>
                        <a href="#" className="menu__item disabled">
                            <svg className="menu__icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5 17H4C3.46957 17 2.96086 16.7893 2.58579 16.4142C2.21071 16.0391 2 15.5304 2 15V5C2 4.46957 2.21071 3.96086 2.58579 3.58579C2.96086 3.21071 3.46957 3 4 3H20C20.5304 3 21.0391 3.21071 21.4142 3.58579C21.7893 3.96086 22 4.46957 22 5V15C22 15.5304 21.7893 16.0391 21.4142 16.4142C21.0391 16.7893 20.5304 17 20 17H19" stroke="inherit" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M12 15L17 21H7L12 15Z" stroke="inherit" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            <div className="menu__text featured" data-text={context.menu_dev} >{context.menu_invoices}</div>
                        </a>
                        <NavLink to="/resources" className="menu__item">
                            <svg className="menu__icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10 13C10.4295 13.5741 10.9774 14.0491 11.6066 14.3929C12.2357 14.7367 12.9315 14.9411 13.6467 14.9923C14.3618 15.0435 15.0796 14.9403 15.7513 14.6897C16.4231 14.4392 17.0331 14.047 17.54 13.54L20.54 10.54C21.4508 9.59695 21.9548 8.33394 21.9434 7.02296C21.932 5.71198 21.4061 4.45791 20.4791 3.53087C19.5521 2.60383 18.298 2.07799 16.987 2.0666C15.676 2.0552 14.413 2.55918 13.47 3.46997L11.75 5.17997" stroke="inherit" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M14 11C13.5705 10.4259 13.0226 9.9508 12.3934 9.60704C11.7642 9.26328 11.0684 9.05886 10.3533 9.00765C9.63816 8.95643 8.92037 9.05961 8.24861 9.3102C7.57685 9.56079 6.96684 9.95291 6.45996 10.46L3.45996 13.46C2.54917 14.403 2.04519 15.666 2.05659 16.977C2.06798 18.288 2.59382 19.542 3.52086 20.4691C4.4479 21.3961 5.70197 21.922 7.01295 21.9334C8.32393 21.9447 9.58694 21.4408 10.53 20.53L12.24 18.82" stroke="inherit" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            <div className="menu__text">{context.menu_resources}</div>
                        </NavLink>
                        {user.status == 2 && <>
                            <NavLink to="/admin" className="menu__item">
                                <svg className="menu__icon" width="24" height="24" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M245.151,168a88,88,0,1,0,88,88A88.1,88.1,0,0,0,245.151,168Zm0,144a56,56,0,1,1,56-56A56.063,56.063,0,0,1,245.151,312Z" className="ci-primary"/><path d="M464.7,322.319l-31.77-26.153a193.081,193.081,0,0,0,0-80.332l31.77-26.153a19.941,19.941,0,0,0,4.606-25.439l-32.612-56.483a19.936,19.936,0,0,0-24.337-8.73l-38.561,14.447a192.038,192.038,0,0,0-69.54-40.192L297.49,32.713A19.936,19.936,0,0,0,277.762,16H212.54a19.937,19.937,0,0,0-19.728,16.712L186.05,73.284a192.03,192.03,0,0,0-69.54,40.192L77.945,99.027a19.937,19.937,0,0,0-24.334,8.731L21,164.245a19.94,19.94,0,0,0,4.61,25.438l31.767,26.151a193.081,193.081,0,0,0,0,80.332l-31.77,26.153A19.942,19.942,0,0,0,21,347.758l32.612,56.483a19.937,19.937,0,0,0,24.337,8.73l38.562-14.447a192.03,192.03,0,0,0,69.54,40.192l6.762,40.571A19.937,19.937,0,0,0,212.54,496h65.222a19.936,19.936,0,0,0,19.728-16.712l6.763-40.572a192.038,192.038,0,0,0,69.54-40.192l38.564,14.449a19.938,19.938,0,0,0,24.334-8.731L469.3,347.755A19.939,19.939,0,0,0,464.7,322.319Zm-50.636,57.12-48.109-18.024-7.285,7.334a159.955,159.955,0,0,1-72.625,41.973l-10,2.636L267.6,464h-44.89l-8.442-50.642-10-2.636a159.955,159.955,0,0,1-72.625-41.973l-7.285-7.334L76.241,379.439,53.8,340.562l39.629-32.624-2.7-9.973a160.9,160.9,0,0,1,0-83.93l2.7-9.972L53.8,171.439l22.446-38.878,48.109,18.024,7.285-7.334a159.955,159.955,0,0,1,72.625-41.973l10-2.636L222.706,48H267.6l8.442,50.642,10,2.636a159.955,159.955,0,0,1,72.625,41.973l7.285,7.334,48.109-18.024,22.447,38.877-39.629,32.625,2.7,9.972a160.9,160.9,0,0,1,0,83.93l-2.7,9.973,39.629,32.623Z" className="ci-primary"/>
                                </svg>
                                <div className="menu__text">Admin</div>
                            </NavLink>
                            <NavLink to="/messages" className="menu__item">
                                <svg className="menu__icon" width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21 15C21 15.5304 20.7893 16.0391 20.4142 16.4142C20.0391 16.7893 19.5304 17 19 17H7L3 21V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V15Z" stroke="inherit" strokeWidth={2} strokeLinecap='round' strokeLinejoin="round"></path>
                                </svg>
                                <div className="menu__text">Сообщения</div>
                            </NavLink>
                            <NavLink to="/stats" className="menu__item">
                                <svg className="menu__icon" width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21.2099 15.89C20.5737 17.3945 19.5787 18.7202 18.3118 19.7513C17.0449 20.7824 15.5447 21.4874 13.9424 21.8048C12.34 22.1221 10.6843 22.0421 9.12006 21.5718C7.55578 21.1015 6.13054 20.2551 4.96893 19.1067C3.80733 17.9582 2.94473 16.5428 2.45655 14.984C1.96837 13.4251 1.86948 11.7705 2.16851 10.1646C2.46755 8.55878 3.15541 7.05063 4.17196 5.77203C5.18851 4.49343 6.5028 3.48332 7.99992 2.83" stroke="inherit" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2V12H22Z" stroke="inherit" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"/>				
                                </svg>
                                <div className="menu__text">Статистика</div>
                            </NavLink>
                        </>}
                    </div>
                </div>
            </div>
            <div className="sidebar__bottom">
                <a href="#" onClick={(e)=>{e.preventDefault(); logOut()}}>
                    <svg className="menu__icon" width="24" height="24" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <polygon points="77.155 272.034 351.75 272.034 351.75 272.033 351.75 240.034 351.75 240.033 77.155 240.033 152.208 164.98 152.208 164.98 152.208 164.979 129.58 142.353 15.899 256.033 15.9 256.034 15.899 256.034 129.58 369.715 152.208 347.088 152.208 347.087 152.208 347.087 77.155 272.034" className="ci-primary"/><polygon points="160 16 160 48 464 48 464 464 160 464 160 496 496 496 496 16 160 16" className="ci-primary"/>
                    </svg>
                    <span>{context.exit}</span>
                </a>
            </div>
        </div>
        <div className="mobile_menu" onClick={() => {document.body.classList.toggle('sidebar_visible')}}>
            <div className="logo">{context.logo}</div>
            <div className="mobile_menu__toggle">
                <div className="toggle__line_1"></div>
                <div className="toggle__line_2"></div>
                <div className="toggle__line_3"></div>
                <div className="toggle__line_4"></div>
            </div>
        </div>
        <div className="content-wrapper">
            <div className="header">
                <div className="account-name">{user.login}</div>
                <div className="account-details">
                    <NavLink to="/payments" className="menu__item">
                        <svg className="menu__icon" width="24" height="24" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path xmlns="http://www.w3.org/2000/svg" d="M296,240H216a46.222,46.222,0,1,1,0-92.444H344v-32H276V56H244v59.556H216A78.222,78.222,0,0,0,216,272h80a46.274,46.274,0,0,1,46.222,46.222v3.556A46.274,46.274,0,0,1,296,368H160.593v32H244v56h32V400h20a78.31,78.31,0,0,0,78.222-78.222v-3.556A78.31,78.31,0,0,0,296,240Z" className="ci-primary"/>
                        </svg>
                        <div className="menu__text">{context.menu_payments}</div>
                    </NavLink>
                    <div className="account-reports">{context.balance}: <b>{user.deposit - user.reports}</b> CFX</div>
                    <div className="account-lang body_s">
                        <div className="account-lang-current" onClick={()=>{setShowLangs(prev => !prev)}}>{lang.toUpperCase()}</div>
                        {showLangs && <div className="account-langs">
                            {langs.map((l,i) => <div key={i} onClick={()=>{changeLang(l)}}>{l.toUpperCase()}</div>)}
                        </div>}
                    </div>
                </div>
            </div>
            <div className="content-body">
                {!status && <h2>{context.tech}</h2>}
                {(status || user.status == 2) &&
                <Routes>
                    <Route path="/" element={<Order context={context} user={user} langs={langs} setUser={setUser} />} />
                    <Route path="/autocheck" element={<Autocheck context={context} user={user} setUser={setUser} />} />
                    <Route path="/payments" element={<Payments context={context} geoPrices={geoPrices} login={user.login} l={l} />} />
                    <Route path="/contacts" element={<Contacts context={context} />} />
                    <Route path="/offer" element={<Offer context={context} />} />
                    <Route path="/sticker" element={<Sticker context={context} />} />
                    <Route path="/invoices" element={<Offer context={context} />} />
                    <Route path="/resources" element={<Resources context={context} />} />
                    {user.status == 2 && <>
                        <Route path="/admin" element={<Admin context={context} />} />
                        <Route path="/messages" element={<Messages />} >
                            <Route path=':user' element={<Chat />} />
                        </Route>
                        <Route path="/stats" element={<Stats />} />
                    </>}
                </Routes>
                }
            </div>
            <div className="content-footer">
                <NavLink to="/contacts" className="menu__item">
                    <svg className="menu__icon" width="24" height="24" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M496,144.768V111.232H456.768V42a25,25,0,0,0-25.179-24.768H80.411A25,25,0,0,0,55.232,42V472a25,25,0,0,0,25.179,24.768H431.589A25,25,0,0,0,456.768,472V400.768H496V367.232H456.768V272.768H496V239.232H456.768V144.768Zm-72.768,94.464H376v33.536h47.232v94.464H376v33.536h47.232v62.464H88.768V50.768H423.232v60.464H376v33.536h47.232Z" className="ci-primary"/><path d="M313.639,306.925h0l-28.745-18.685,13.82-33.655V201.714a65.714,65.714,0,1,0-131.428,0v52.557l12.721,34.684-27.646,17.97A48.972,48.972,0,0,0,130,348.129V400H336V348.129A48.972,48.972,0,0,0,313.639,306.925ZM304,368H162V348.129a17.084,17.084,0,0,1,7.8-14.373l49.033-31.872-19.547-53.3V201.714a33.714,33.714,0,0,1,67.428,0v46.557l-21.5,52.347L296.2,333.756h0a17.084,17.084,0,0,1,7.8,14.373Z" className="ci-primary"/>
                    </svg>
                    <div className="menu__text">{context.menu_contacts}</div>
                </NavLink>
                <NavLink to="/offer" className="menu__item">
                    <svg className="menu__icon" width="24" height="24" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path xmlns="http://www.w3.org/2000/svg" d="M101.667,400H464V16H100.667A60.863,60.863,0,0,0,40,76.667V430.25h.011c0,.151-.011.3-.011.453,0,35.4,27.782,65.3,60.667,65.3H464V464H100.667C85.664,464,72,448.129,72,430.7,72,414.06,85.585,400,101.667,400ZM360,48.333V221.149l-48.4-42.49L264,220.9V48.333ZM232,48V264h31.641l48.075-42.659L360.305,264H392V48h40V368H136.08L136,48ZM100.667,48H104l.076,320h-2.413A59.793,59.793,0,0,0,72,375.883V76.917A28.825,28.825,0,0,1,100.667,48Z" className="ci-primary"/>
                    </svg>
                    <div className="menu__text">{context.menu_offer}</div>
                </NavLink>
            </div>
        </div>
    </div>
    )
}