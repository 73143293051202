import { useEffect, useState } from "react"

const stickers = [
    {name: "Buick (2020+)", value: "https://cws.gm.com/vs-cws/vehshop/v2/vehicle/windowsticker"},
    {name: "Cadillac (2020+)", value: "https://cws.gm.com/vs-cws/vehshop/v2/vehicle/windowsticker"},
    {name: "Chevrolet (2020+)", value: "https://cws.gm.com/vs-cws/vehshop/v2/vehicle/windowsticker"},
    {name: "Chrysler (2013+)", value: "https://www.chrysler.com/hostd/windowsticker/getWindowStickerPdf.do"},
    {name: "Dodge (2013+)", value: "https://www.dodge.com/hostd/windowsticker/getWindowStickerPdf.do"},
    {name: "Fiat (2015+)", value: "https://www.fiatusa.com/hostd/windowsticker/getWindowStickerPdf.do"},
    {name: "Jeep (2013+)", value: "https://www.jeep.com/hostd/windowsticker/getWindowStickerPdf.do"},
    {name: "RAM (2014+)", value: "https://www.ramtrucks.com/hostd/windowsticker/getWindowStickerPdf.do"}
];

export default function Sticker({context}) {
    const [open,setOpen] = useState(false)
    const [action,setAction] = useState(['',''])
    const [vin,setVin] = useState('')

    const handleSubmit = (e) => {
        if (action[0] == '' || vin == '') {
            e.preventDefault();
            return false;
        }
    }

    return(<>
        <h2>{context.menu_sticker}</h2>
        <form action={action[0]} method="get" onSubmit={handleSubmit} style={{maxWidth: 400}}>
            <div className="input-wrapper" id="make" onClick={()=>{setOpen((prev) => !prev)}}>
                <div className={`input js-select ${open ? 'opened' : ''}`}>
                    <input type="hidden" />
                    <span>{context.sticker_select}</span>
                    <div className="var">{action[1]}</div>
                    <svg width="20" height="20" viewBox="0 0 20 20" stroke="#C7C9D1" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4 6L8 10L12 6" stroke="inherit" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>
                    <div className="variants">
                        {stickers.map((l,i) => <div key={i} className="variant" onClick={(e)=>{setAction([l.value,l.name])}} data={l.value}>{l.name}</div>)}
                    </div>
                </div>
            </div>
            
            <div className="input-wrapper">
                <input type="text" id="vin" name="vin" maxLength="17" onInput={(e) => {setVin(e.currentTarget.value)}} value={vin} required />
                <span>{context.stickerEnterVin}</span>
            </div>
            
            <button className="btn" type="submit" id="submit">{context.sticker_btn}</button>
        </form>
    </>)
}