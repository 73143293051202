import { useRef, useState } from "react"
import reload from "../update.png"
import { url } from '../components/settings'
import { useEffect } from "react"


export default function Autocheck({context, user, setUser}) {
    const [submit,setSubmit] = useState(null)
    const [reports,setReports] = useState([])
    const [error,setError] = useState(false)
    const ref = useRef()

    const handleSubmit = (e) => {
        e.preventDefault()
        if (ref.current.reportValidity()) {
          let formData = new FormData(ref.current)
          formData.append('token',window.localStorage['token'])
          fetch(url + '?action=getAutocheckReport', {method: 'POST', body: formData})
          .then(response => response.json())
          .then(r => {
            if (r.result) {
                setError(false)
                setSubmit(context.orderPending)
                ref.current.querySelectorAll('input[type="text"]').forEach(inp => {inp.value = ''})
                const newUser = {...user}
                newUser.reports = +newUser.reports + 1
                setUser(newUser)
                getReports()
            } else {
                setError(true)
                setSubmit(context[r.error])
            }
          })
        }
    }

    const getReports = () => {
        let formData = new FormData()
        formData.append('token',window.localStorage['token'])
        fetch(url + '?action=getAutocheckReports', {method: 'POST', body: formData})
        .then(response => response.json())
        .then(r => {
            if (r.result) {
                setReports(r.data)
            }
        })
    }

    useEffect(()=>{
        getReports()
    },[])

    return(<div className="align-center">
        <h2>{context.orderTitle}</h2>  
        <form ref={ref} onSubmit={handleSubmit}>
        <div className="input-wrapper">
            <input type="text" className="form-control" placeholder="vin" name="vin" required />
            <span>vin</span>
        </div>
        <div className="row">
            <div className="col"></div>
            <div className="col">
                <button className="btn btn-primary px-4" type="submit">{context.orderBtn}</button>
            </div>
        </div>
        <div className={`submit-results body_s ${error ? 'error' : ''} ${submit ? '' : 'hidden'}`}>{submit}</div>
        </form>	
        <div className="history-info">
            <p>{context.orderReports}: <b>{user.reports}</b>. {context.orderDeposit}: <b>{user.deposit}</b>.</p>
            <p>{context.orderAvailable}: <b>{+user.deposit - +user.reports}</b>.</p>
            <p>{context.orderOverdraft}: <b>{user.overdraft}</b>.</p>
        </div>
        <div className="table-wrapper">
        <table className="body_s">
            <thead>
                <tr>
                    <th>VIN</th>
                    <th>{context.orderData}</th>
                    <th>{context.orderStatus}</th>
                    <th>{context.orderLink}
                        <a
                          className="update-reports-table"
                          onClick={(e)=>{e.preventDefault(); getReports()}} 
                          title={context.orderReload}
                          href="#"
                        ><img src={reload} /></a>		
                    </th>
                </tr>
            </thead>
            <tbody>
                {reports.map(r => {
                    return(
                    <tr key={r.id}>
                        <td>{r.vin}</td>
                        <td>{r.time}</td>
                        <td>
                            {r.status == 2 && <span className="badge body_xs badge-error">{context.orderStatusError}</span>}
                            {r.status == 1 && <span className="badge body_xs badge-success">{context.orderStatusComplete}</span>}
                            {r.status == 0 && <span className="badge body_xs badge-warning">{context.orderStatusPending}</span>}
                        </td>
                        <td>
                            {r.link && <a href={r.link}>{context.orderDownload}</a>}
                        </td>
                    </tr>
                )})}
            </tbody>
        </table>
        </div>
    </div>)
}