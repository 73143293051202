import { useState } from 'react';
import { BrowserRouter } from "react-router-dom";
import './App.css';
import FormLogin from './components/FormLogin'
import FormReg from './components/FormReg'
import FormForgot from './components/FormForgot'
import MainPage from './components/MainPage'
import Offer from './components/Offer';
import Policy from './components/Policy';
import Pay from './components/Pay';
import Lk from './Lk';
import logo from './logo.jpg'

function App({context, geoPrices, langs, setL, l}) {
  const [page,setPage] = useState('start')
  const [token,setToken] = useState(window.localStorage['token'])
  const [lang,setLang] = useState(window.localStorage['lang'])
  const [showLangs,setShowLangs] = useState(false)
  const [vin,setVin] = useState(null)
  const [email,setEmail] = useState(null)

  const logOut = () => {
    window.localStorage.removeItem('token')
    setToken(null)
  }
  const changeLang = (l) => {
    window.localStorage['lang'] = l
    setLang(l)
    setL(l)
    setShowLangs(false)
  }
  const scrollTo = (selector) => {
    if (page != 'start') setPage('start')
    window.setTimeout(()=>{
      let elem = document.querySelector(selector)
      if (elem) {
        elem.scrollIntoView()
      }
    },10)
  }
  const testMain = false
  return (<>
    {(testMain || !token) &&
    <div className="App">
      <header>
        <div className="container flex-container">
          <div>
            <img src={logo} className='main-logo' alt="Carfaxshara logo" onClick={(e)=>{e.preventDefault(); setPage('start')}} />
          </div>
          <div className="menu">
            <div onClick={()=>{scrollTo("#check")}} className="menu-item">{context.checkVin}</div>
            <div onClick={()=>{scrollTo("#opt")}} className="menu-item">{context.packages}</div>
            <div onClick={()=>{scrollTo("#faq")}} className="menu-item">{context.about}</div>
            <div onClick={()=>{scrollTo("#example")}} className="menu-item">{context.example}</div>
            <div onClick={()=>{scrollTo("#tech")}} className="menu-item">{context.support}</div>
          </div>
          <div className="account-details">
            <button className="btn" onClick={(e)=>{e.preventDefault(); setPage('login')}}>{context.loginBtn}</button>
            <div className="account-lang body_s">
                <div className="account-lang-current" onClick={()=>{setShowLangs(prev => !prev)}}>{lang.toUpperCase()}</div>
                {showLangs && <div className="account-langs">
                    {langs.map((l,i) => <div key={i} onClick={()=>{changeLang(l)}}>{l.toUpperCase()}</div>)}
                </div>}
            </div>
          </div>
        </div>
      </header>
      {(['login','reg','forgot'].indexOf(page) >= 0) && <div className="card-body">
        {page === 'login' && <FormLogin context={context} setToken={setToken} setPage={setPage} />}
        {page === 'reg' && <FormReg context={context} setPage={setPage} />}
        {page === 'forgot' && <FormForgot context={context} setPage={setPage} />}
      </div>}
      {(['start','offer','policy','pay'].indexOf(page) >= 0) && <div className="main-page">
        {page === 'start' && <MainPage context={context} geoPrices={geoPrices} vin={vin} setVin={setVin} email={email} setEmail={setEmail} toPay={()=>{setPage('pay')}} toLogin={()=>setPage('login')} />}
        {page === 'offer' && <Offer />}
        {page === 'policy' && <Policy />}
        {page === 'pay' && <Pay context={context} geoPrices={geoPrices} email={email} vin={vin} toMain={(e)=>{e.preventDefault(); setPage('start')}} l={l} />}
      </div>}
      <footer>
        <div className="container flex-container">
          <div>@ 2023 | {context.contacts}: <a href="mailto:yaksvoimcars@gmail.com">yaksvoimcars@gmail.com</a> | <a href="https://t.me/carfaxshara_bot">Telegram Bot</a> | <a href="https://instagram.com/carfaxshara">Instagram</a> | <a href="https://t.me/carfaxshara">For partners</a></div>
          <div><span onClick={()=>{setPage('offer')}}>{context.menu_offer}</span> | <span onClick={()=>{setPage('policy')}}>{context.policy}</span></div>
        </div>
      </footer>
    </div>}
    <BrowserRouter>
      {!testMain && token && <Lk context={context} geoPrices={geoPrices} langs={langs} logOut={logOut} setL={setL} l={l} />}
    </BrowserRouter>
  </>);
}

export default App;
